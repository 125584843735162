import React, {
  UIEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

import configs from 'configs';
import { IMessage, TImage } from 'types';
import { TDirective } from 'api/socket';
import { frontDate } from 'utils/format';
import { evaluateMessage } from 'store/actions';

import Button from 'components/button';
import Loading from 'components/loading';

import InfoIcon from 'assets/icons/info.svg';
import CloseIcon from 'assets/icons/close.svg';
import ThumbIcon from 'assets/icons/thumb.svg';

import styles from '../styles';

interface IProps {
  loading?: boolean;
  generating?: boolean;
  messages?: IMessage[];
  directive?: TDirective;
  inactive?: boolean;
  limitReached?: boolean;
  typing?: boolean;
  error?: string;
  editing?: string | false;
  className?: string;
  onScroll?: (message: number | undefined) => void;
  onImageClick?: (url: string) => void;
  onDirectiveClose?: () => void;
}

type TCollapsedMessage = IMessage & { index: number; messages?: string[] };

const CollapsedMessage: React.FC<
  TCollapsedMessage & { typing: boolean; editing: boolean }
> = ({ typing, editing, id, messages, actor, evaluation, datetime, index }) => {
  const [value, setValue] = useState(evaluation || 0);
  const [evaluating, setEvaluating] = useState<boolean>(false);
  const evaluate = (up: boolean) => async () => {
    if (!id) return;
    setEvaluating(true);
    const rate = up ? 1 : -1;
    setValue(current => current + rate);
    const newValue = await evaluateMessage(id, rate);
    newValue !== false ? setValue(newValue) : setValue(evaluation);
    setEvaluating(false);
  };
  return (
    <div
      data-index={index}
      className={cn(styles.block, styles[actor], {
        [styles.editing]: editing,
      })}
    >
      {messages?.map(
        (line, lineIndex) =>
          !!line.trim() && (
            <p
              key={`messages-${id}-line-${lineIndex.toString()}`}
              translate={typing || editing ? 'no' : undefined}
              className={cn({
                notranslate: typing || editing,
              })}
            >
              {line}
            </p>
          )
      )}
      {!configs.ui.headerTime && (
        <span className={styles.date}>{frontDate(datetime)}</span>
      )}
      {!typing && !editing && actor === 'assistant' && (
        <div
          className={cn(styles.evaluation, { [styles.loading]: evaluating })}
        >
          <div>
            <button disabled={value > 0} onClick={evaluate(true)}>
              <img src={ThumbIcon} alt="Thumb up" />
            </button>
            <button disabled={value < 0} onClick={evaluate(false)}>
              <img src={ThumbIcon} alt="Thumb down" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const Dialog: React.FC<IProps> = ({
  loading,
  generating,
  messages,
  directive,
  inactive,
  limitReached,
  typing,
  error,
  editing,
  className,
  onScroll,
  onImageClick,
  onDirectiveClose,
}) => {
  const dialogueRef = useRef<HTMLDivElement>(null);

  const currentMessageRef = useRef<number>();
  const updateCurrentMessage = () => {
    if (!configs.ui.headerTime || !dialogueRef.current || !onScroll) return;
    if (!messages?.length) {
      currentMessageRef.current = undefined;
      onScroll(undefined);
      return;
    }
    for (let i = dialogueRef.current.children.length - 1; i >= 0; i--) {
      if (
        dialogueRef.current.children[i].getBoundingClientRect().top <
        dialogueRef.current.getBoundingClientRect().bottom - 40
      ) {
        const value = (dialogueRef.current.children[i] as HTMLElement)?.dataset
          ?.index;
        if (!value?.length) continue;
        if (currentMessageRef.current !== +value) {
          currentMessageRef.current = +value;
          onScroll(+value);
        }
        break;
      }
    }
  };

  const needAutoscrollRef = useRef<boolean>(true);
  const handleScroll: UIEventHandler<HTMLDivElement> = ({ target }) => {
    const div = target as HTMLDivElement;
    needAutoscrollRef.current =
      div.scrollTop >= div.scrollHeight - div.clientHeight - 1;
    updateCurrentMessage();
  };

  const needLimitReached = !!messages?.length && !typing && limitReached;

  // scroll to bottom when receive new message
  const scrollDialogueToBottom = () => {
    if (needAutoscrollRef.current && dialogueRef.current) {
      const div = dialogueRef.current as HTMLDivElement;
      setTimeout(() => div.scrollTo(0, div.scrollHeight), 0);
    }
  };
  useEffect(() => {
    scrollDialogueToBottom();
    updateCurrentMessage();
  }, [
    generating,
    messages?.length,
    messages?.at(-1)?.message,
    inactive,
    needLimitReached,
  ]);
  const handleImgLoad = (img: TImage) => {
    messages?.at(-1)?.img === img && scrollDialogueToBottom();
  };

  const collapsedMessages = useMemo(() => {
    if (!configs.ui.collapseMessages) return [];
    const result: TCollapsedMessage[] = [];
    let block: TCollapsedMessage;
    messages?.forEach(({ message, img, ...item }, index) => {
      if (
        index === 0 ||
        item.id !== messages[index - 1].id ||
        item.actor !== messages[index - 1].actor ||
        img !== messages[index - 1].img
      ) {
        block = { ...item, index };
        !img && message?.length && (block.messages = ['']);
        result.push(block);
      }
      if (img) {
        block.img = img;
        block.message = message;
      } else if (message?.length && block.messages) {
        block.messages[block.messages.length - 1] = [
          block.messages[block.messages.length - 1],
          message,
        ].join(' ');
        if (message.endsWith('\n') && messages[index + 1]?.id === item.id) {
          block.messages?.push('');
        }
      }
    });
    return result;
  }, [messages?.at(-1)?.id, messages?.at(-1)?.message]);

  const SingleMessage: React.FC<{
    index: number;
    data: IMessage;
    denyTranslate?: boolean;
  }> = useCallback(
    ({ index, data, denyTranslate }) => (
      <>
        {data.img && (
          <img
            src={data.img.preview_url}
            alt={data.message}
            title={data.message}
            className={styles[data.actor]}
            data-index={index}
            onClick={() => data.img && onImageClick?.(data.img?.full_url)}
            onLoad={() => data.img && handleImgLoad(data.img)}
          />
        )}
        {!!data.message?.trim() && (
          <p
            translate={denyTranslate ? 'no' : undefined}
            className={cn(styles[data.actor], {
              [styles.editing]: data.id === editing,
              notranslate: denyTranslate,
            })}
            data-index={index}
          >
            {data.message}
            {!configs.ui.headerTime && (
              <span className={styles.date}>{frontDate(data.datetime)}</span>
            )}
          </p>
        )}
      </>
    ),
    [onImageClick, handleImgLoad]
  );

  return (
    <div
      ref={dialogueRef}
      className={cn(styles.messages, className)}
      onScroll={handleScroll}
    >
      {directive && (
        <div className={styles.directive}>
          <div>
            <div>
              <img src={InfoIcon} />
            </div>
            <p>
              <strong>Intent:</strong> {directive.intent}
            </p>
            <p>
              <strong>Directive:</strong> {directive.directive}
            </p>
            <Button icon={CloseIcon} onClick={onDirectiveClose} />
          </div>
        </div>
      )}
      {configs.ui.collapseMessages
        ? collapsedMessages?.map((data, blockIndex) => {
            const typingThis =
              !!typing &&
              data.actor === 'assistant' &&
              blockIndex === collapsedMessages?.length - 1;
            if (data.img) {
              return (
                <SingleMessage
                  key={`messages-${blockIndex.toString()}`}
                  index={data.index}
                  data={data}
                  denyTranslate={typingThis}
                />
              );
            }
            return (
              <CollapsedMessage
                key={`messages-${blockIndex.toString()}`}
                {...data}
                editing={data.id === editing}
                typing={typingThis}
              />
            );
          })
        : messages?.map((data, index) => {
            const denyTranslate =
              typing &&
              data.actor === 'assistant' &&
              index === messages?.length - 1;
            return (
              <SingleMessage
                key={`messages-${index.toString()}`}
                index={index}
                data={data}
                denyTranslate={denyTranslate}
              />
            );
          })}
      {generating && (
        <p className={styles.loading}>
          Typing <span>.</span>
          <span>.</span>
          <span>.</span>
        </p>
      )}
      {!generating && loading && (
        <p className={styles.loading}>
          <Loading size={16} className={styles.spinner} />
          Loading <span>.</span>
          <span>.</span>
          <span>.</span>
        </p>
      )}
      {!!error?.length && <p className={styles.error}>{error}</p>}
      {!!messages?.length && inactive && (
        <p className={styles.completed}>This chat has been completed</p>
      )}
      {needLimitReached && (
        <div className={styles.limit}>
          <p>Messages limit has been reached</p>
          <Button caption="Sign Up" disabled />
        </div>
      )}
    </div>
  );
};

export default Dialog;
