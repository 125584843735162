const LETTERS_PER_MINUTE = 1000;

export type TTypingCallback = (
  word: string,
  first?: boolean,
  last?: boolean
) => void;

export type TBetweenCallback = () => void;

interface IQueue {
  text?: string;
  callback: TTypingCallback | TBetweenCallback;
  lettersPerMinute?: number;
}

const queue: IQueue[] = [];
let processing = false;

export const type = (
  text: string,
  callback: TTypingCallback,
  lettersPerMinute?: number
) => {
  queue.push({ text, callback, lettersPerMinute });
  !processing && processNextMessage();
};

export const between = (callback: TBetweenCallback) => {
  queue.push({ callback });
  !processing && processNextMessage();
};

const processNextMessage = async () => {
  processing = !!queue.length;
  if (!processing) return;
  const { text, callback, lettersPerMinute } = queue[0];
  if (text) {
    const words = text.trim().split(/\s+/);
    const message = [];
    for (const i in words) {
      message.push(words[i]);
      (callback as TTypingCallback)(
        message.join(' '),
        +i === 0,
        +i >= words.length - 1
      );
      const delay =
        (60 / (lettersPerMinute || LETTERS_PER_MINUTE)) *
        1000 *
        words[i].length;
      await new Promise(r => setTimeout(r, delay));
    }
  } else {
    (callback as TBetweenCallback)();
  }
  queue.shift();
  processNextMessage();
};
