import React, {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import ImageViewer from 'react-simple-image-viewer';

import configs, { domain } from 'configs';
import store from 'store';
import { download } from 'api';
import { getHistory } from 'api/http';
import { useDebounceProcess } from 'utils/debounce';
import { backDate, frontDate } from 'utils/format';
import useConnection from 'utils/connection';
import * as actions from 'store/actions';
import * as socket from 'api/socket';
import * as player from 'services/player';
import * as recorder from 'services/recorder';
import * as typist from 'services/typist';

import Switch from 'components/switch';
import Themer from 'components/themer';
import Loading from 'components/loading';
import Button from 'components/button';
import Input, { InputRef } from 'components/input';
import Select from 'components/select';

import Logo from 'components/icons/logo';
import SifuLogo from 'components/icons/sifu';
import PrescreenLogo from 'components/icons/prescreen';

import VolumeMuteIcon from 'assets/icons/volume-mute.svg';
import VolumeHighIcon from 'assets/icons/volume-high.svg';
import SendIcon from 'assets/icons/send.svg';
import MicrophoneIcon from 'assets/icons/microphone.svg';
import MicrophoneDenyIcon from 'assets/icons/microphone-deny.svg';
import LoaderIcon from 'assets/icons/loader.svg';
import MenuIcon from 'assets/icons/menu.svg';
import CloseIcon from 'assets/icons/close.svg';
import CalendarIcon from 'assets/icons/calendar.svg';

import Authorization from './components/authorization';
import PasswordForm from './components/password';
import Dialog from './components/dialog';
import Chats, { chatsTitle } from './components/chats';
import Policy, { getPolicy, policyTitle } from './components/policy';

import styles from './styles';

const CUBIT_ASSISTANT = 'Cubit Assistant';
const PAUSE_DELAY_MS = 120;

const titles = {
  policy: policyTitle,
  history: chatsTitle,
};

const needPolicy = !!getPolicy();

export interface ChatRef {
  open: () => void;
  close: () => void;
  toggle: () => void;
}

type TSocketStatus = 'disconnected' | 'connected' | 'ready';

interface IProps {
  className?: string;
}

const Chat = forwardRef<ChatRef, IProps>(({ className }, ref) => {
  const {
    bots,
    botId,
    voiceId,
    cubitLessons,
    cubitLesson,
    messages,
    user,
    status,
  } = store;

  const bot = actions.getBot(botId);
  const voices = bot?.voices || [];

  const isCubit = bot?.name === CUBIT_ASSISTANT;
  const isCubitConfigured = !isCubit || !!cubitLesson.length;

  const isOnline = useConnection();
  const [showOnline, setShowOnline] = useState<boolean>(!isOnline);

  const isActive = status !== 'inactive';

  const connectionTimerRef = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    clearTimeout(connectionTimerRef.current);
    if (isOnline) {
      connectionTimerRef.current = setTimeout(
        () => setShowOnline(false),
        2_000
      );
    } else {
      setShowOnline(true);
    }
  }, [isOnline]);

  useEffect(() => {
    if (isCubitConfigured) return;
    actions.loadCubitLessons();
  }, [isCubitConfigured]);

  const [visible, setVisible] = useState<boolean>(false);
  const handleToggle = () => setVisible(prev => !prev);
  useImperativeHandle(ref, () => ({
    open: () => setVisible(true),
    close: () => setVisible(false),
    toggle: handleToggle,
  }));

  useEffect(() => {
    if (bots?.length === 1) {
      actions.setBot(bots[0].id);
      if (!configs.allowVoice) handleSetTextual(true);
    }
    if (voices?.length === 1) actions.setVoice(voices[0].id);
  }, [bots, voices]);

  const isAuthorized = !!user && !!bots.length;
  const [inited, setInited] = useState<boolean>(false);
  useEffect(() => {
    if (isAuthorized) return;
    (async () => {
      const existToken = actions.restoreToken();
      const result = existToken && (await actions.loadUserData());
      result && (await actions.loadBots());
      setInited(true);
    })();
  }, []);

  const [focused, setFocused] = useState(true);
  useEffect(() => {
    const handleFocus = () => setFocused(true);
    const handleBlur = () => setFocused(false);
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);
    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  const [policy, setPolicy] = useState<boolean>(false);
  const handleOpenPolicy = () => setPolicy(true);
  const handleClosePolicy = () => setPolicy(false);

  const [playing, startPlaying, finishPlaying] = useDebounceProcess(500);
  const [typing, startTyping, finishTyping] = useDebounceProcess(500);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [textual, setTextual] = useState<boolean | null>(null);
  const [silent, setSilent] = useState<boolean>(false);
  const [generating, setGenerating] = useState<boolean>(false);
  const [permitted, setPermitted] = useState<boolean>(false);
  const [recording, setRecording] = useState<boolean>(false);
  const [recognizing, setRecognizing] = useState<boolean>(false);
  const [muted, setMuted] = useState<boolean>(false);

  const [passwording, setChangingPassword] = useState<boolean>(false);
  const [chats, setChats] = useState<boolean>(false);
  const [editing, setEditing] = useState<string | false>(false);
  const [directive, setDirective] = useState<socket.TDirective>();
  const handleCloseDirective = () => setDirective(undefined);

  const isTextualSetted = textual !== null;
  const handleSetTextual = (value: boolean) => {
    player.init();
    setTextual(value);
  };

  const isMessagesEmpty = !messages.length;

  const toggleSilent = () => setSilent(value => !value);

  useEffect(() => {
    player.mute(silent || !!textual);
  }, [silent, textual]);

  const handleSetMutedClick = () => setMuted(val => !val);

  const [socketStatus, setSocketStatus] =
    useState<TSocketStatus>('disconnected');
  useEffect(
    () =>
      socket.handleSocketStatus(isConnected => {
        setSocketStatus(isConnected ? 'connected' : 'disconnected');
        if (isOnline && isActive && !isConnected) {
          setLoaded(true);
          setError('Socket connection is failed');
        } else if (isConnected) {
          setError('');
        }
      }),
    [isOnline, isActive]
  );
  const isSocketReady = socketStatus === 'ready';

  const isUser = !user?.moderator && !user?.admin;
  const isAnon = !!user?.anon_uuid;

  const isLimitReached =
    !!bot?.anon_messages &&
    !generating &&
    messages.at(-1)?.actor === 'assistant' &&
    bot.anon_messages <=
      messages.filter(({ actor }) => actor === 'user').length * 2;

  useEffect(() => {
    (isSocketReady || generating || typing || isLimitReached) &&
      setLoaded(true);
  }, [isSocketReady, generating, typing, isLimitReached]);

  const focusedTimerRef = useRef<ReturnType<typeof setTimeout>>();
  const [paused, setPaused] = useState(false);
  const handleResume = () => setPaused(false);
  useEffect(() => {
    if (!focused) {
      focusedTimerRef.current = setTimeout(
        () => setPaused(true),
        PAUSE_DELAY_MS
      );
    } else {
      clearTimeout(focusedTimerRef.current);
    }
    if (!isTextualSetted || typing || generating || !isOnline) return;
    focused && !isCubit && updateHistory();
  }, [focused]);

  useEffect(() => {
    if (!isAuthorized || !bot) {
      setLoaded(false);
      setError('');
      setPaused(false);
    }
  }, [isAuthorized, bot]);

  const updateHistory = () =>
    getHistory()
      .then(history => {
        actions.setMessages(history.messages);
        actions.setChatStatus(history.status);
      })
      .catch(() => null);

  const prevOnlineStatus = useRef<boolean>(isOnline);
  useEffect(() => {
    if (
      !isOnline ||
      !visible ||
      !isAuthorized ||
      !isTextualSetted ||
      !isCubitConfigured
    ) {
      setGenerating(false);
    } else if (isMessagesEmpty || !prevOnlineStatus.current) {
      !isCubit && updateHistory();
    }
    prevOnlineStatus.current = isOnline;
  }, [
    isTextualSetted,
    isCubitConfigured,
    isCubit,
    visible,
    isMessagesEmpty,
    isAuthorized,
    isOnline,
  ]);

  const startRecording = async () => {
    const isPermitted = await recorder.check();
    setPermitted(isPermitted);
    if (!isPermitted) return;
    await recorder.start(blob => socket.emitAudio(blob));
    setRecording(true);
  };
  const stopRecording = () => {
    setRecording(false);
    recorder.stop();
  };

  useEffect(() => {
    if (isCubit) return;
    if (
      !isTextualSetted ||
      !visible ||
      playing ||
      typing ||
      generating ||
      textual ||
      muted ||
      !isOnline ||
      !isActive ||
      !isSocketReady ||
      isLimitReached
    ) {
      stopRecording();
    } else {
      startRecording();
    }
  }, [
    isTextualSetted,
    visible,
    playing,
    typing,
    generating,
    textual,
    muted,
    isOnline,
    isActive,
    isSocketReady,
    isLimitReached,
    isCubitConfigured,
  ]);

  useEffect(() => {
    if (
      isTextualSetted &&
      !textual &&
      !muted &&
      !isOnline &&
      !isActive &&
      isLimitReached
    ) {
      setMuted(true);
    }
  }, [isTextualSetted, textual, muted, isOnline, isActive, isLimitReached]);

  const sendMessage = (message: string) => {
    if (!message.length) return;
    if (isCubit) {
      socket.emitCubitMessage(message, voiceId, !textual);
    } else {
      socket.emitMessage(message, voiceId, !textual);
    }
    actions.addMessage({
      message,
      actor: 'user',
      evaluation: 0,
      datetime: backDate(),
    });
  };

  const sendEditedMessage = (message: string) => {
    if (!message.length) return;
    socket.emitEditMessage(message, voiceId);
    setEditing(false);
  };

  const handleLogOut = () => {
    setPolicy(false);
    actions.logOut();
  };
  useEffect(() => {
    if (!isAuthorized) {
      clearInput();
      setChats(false);
    }
  }, [isAuthorized]);

  // connect to socket
  useEffect(() => {
    setError('');

    if (!isAuthorized || !bot) {
      setTextual(null);
      setDirective(undefined);
      actions.clearMessages();
      isCubit && actions.setCubitLesson('');
      return;
    }

    if (!isOnline || !isActive || paused || isLimitReached) return;

    const disconnectSocket = socket.connectSocket(voiceId, !textual);

    const unhandleReadiness = socket.handleReadiness(() =>
      setTimeout(() => setSocketStatus('ready'), 0)
    );

    const unhandleVoiceRecognizing =
      socket.handleVoiceRecognizing(setRecognizing);

    const unhandleVoiceToText = socket.handleVoiceToText(sendMessage);

    const unhandleResponse = socket.handleResponse(
      ({ id, message, audio, img, evaluation, datetime }) => {
        const typeMessage = () => {
          img &&
            typist.between(() => {
              actions.addMessage({
                id,
                img,
                actor: 'assistant',
                evaluation,
                datetime,
              });
              finishTyping();
            });
          message?.trim().length &&
            typist.type(
              message?.trim(),
              (word, first, last) => {
                startTyping();
                last && finishTyping();
                first
                  ? actions.addMessage({
                      id,
                      message: word,
                      actor: 'assistant',
                      evaluation,
                      datetime,
                    })
                  : actions.editLastMessage(word);
              },
              !configs.ui.typing ? 5_000 : undefined
            );
        };
        if (player.getInited() && !!audio) {
          player.play(
            audio,
            () => {
              typeMessage();
              startPlaying();
            },
            finishPlaying
          );
        } else {
          typeMessage();
        }
      }
    );

    const unhandleGodModeResponse = socket.handleGodModeResponse(directive => {
      setDirective(directive);
      !isCubit && updateHistory();
    });

    const unhandleStartGenerating = socket.handleStartGenerating(() =>
      setGenerating(true)
    );

    const unhandleStopGenerating = socket.handleStopGenerating(error => {
      setGenerating(false);
      error && console.error(error);
    });

    const unhandleChatStatus = socket.handleChatStatus(actions.setChatStatus);

    const unhandleUserStatus = socket.handleUserStatus(
      userStatus => userStatus !== 'active' && handleLogOut()
    );

    const unhandleVoiceActivity = socket.handleVoiceActivity(() => undefined);
    const unhandleWarning = socket.handleWarning(() => undefined);
    const unhandleError = socket.handleError(() => undefined);

    return () => {
      unhandleReadiness();
      unhandleVoiceRecognizing();
      unhandleVoiceToText();
      unhandleResponse();
      unhandleGodModeResponse();
      unhandleStartGenerating();
      unhandleStopGenerating();
      unhandleChatStatus();
      unhandleUserStatus();
      unhandleVoiceActivity();
      unhandleWarning();
      unhandleError();
      disconnectSocket();
    };
  }, [isAuthorized, bot, isOnline, isActive, paused, isLimitReached, isCubit]);

  const inputRef = useRef<InputRef>(null);
  const messageRef = useRef<string>('');

  const getInputScrollHeight = () => inputRef.current?.getScrollHeight() || 0;
  const setInputFocus = () => inputRef.current?.setFocus();
  const getInputText = (needClear: boolean) =>
    inputRef.current?.getValue(needClear).trim() || '';
  const fillInput = (value: string) => inputRef.current?.setValue(value);
  const clearInput = () => {
    inputRef.current?.setValue('');
    setEditing(false);
  };

  const [inputHeight, setInputHeight] = useState<number>(0);
  const updateInputHeight = () => setInputHeight(getInputScrollHeight());

  useEffect(() => {
    updateInputHeight();
    fillInput(messageRef.current || '');
  }, [textual]);

  const handleSendMessage = () => {
    const isEditing = editing && textual;
    let message = getInputText(true);
    if (!message.length) return;
    isEditing ? sendEditedMessage(message) : sendMessage(message);
  };

  const [isMessageFilled, setMessageFilled] = useState<boolean>(false);
  const handleEditMessage = (value: string) => {
    setMessageFilled(!!value?.trim()?.length);
    messageRef.current = value;
    updateInputHeight();
  };

  const [creatingSession, setCreatingSession] = useState<boolean>(false);
  const handleNewSessionClick = async () => {
    try {
      setCreatingSession(true);
      await actions.createNewSession(!textual);
      setPaused(false);
    } finally {
      setCreatingSession(false);
    }
  };

  const handleChangePassword = () => {
    clearInput();
    isTextualSetted && setTextual(true);
    setChangingPassword(true);
  };
  const handleClosePasswordForm = () => setChangingPassword(false);
  const isChangePasswordDisabled =
    passwording || chats || !!editing || !isOnline || policy;

  const isSendFieldDisabled =
    !isTextualSetted ||
    generating ||
    typing ||
    playing ||
    passwording ||
    chats ||
    !isActive ||
    !isSocketReady ||
    isLimitReached ||
    policy ||
    !isCubitConfigured;
  const isGetProfileDisabled = !messages.filter(({ actor }) => actor === 'user')
    .length;
  const isGetHistoryDisabled = isGetProfileDisabled;
  const isNewSessionDisabled =
    !isTextualSetted ||
    !!editing ||
    typing ||
    generating ||
    creatingSession ||
    passwording ||
    chats ||
    policy;
  const isMenuDisabled = !isAuthorized || !isCubitConfigured || !isOnline;

  const isStartDisabled = !bot || (configs.allowVoice && !voiceId) || !isOnline;

  const footerRef = useRef<HTMLDivElement>(null);
  const [footerHeight, setFooterHeight] = useState<number>(0);
  useEffect(() => {
    const calcFooterHeight = () => {
      const footer = footerRef.current as HTMLDivElement;
      setFooterHeight(footer.offsetHeight);
    };
    calcFooterHeight();
    window.addEventListener('resize', calcFooterHeight);
    return () => window.removeEventListener('resize', calcFooterHeight);
  }, []);

  const [viewingImage, setViewingImage] = useState<string | null>(null);

  const handleDownloadProfile = () =>
    download('generate_profile', {}, 'ProfileData');

  const handleDownloadHistory = () =>
    download(
      'download_session_history',
      { extension: 'pdf' },
      'SessionHistory'
    );

  const isEditBotMessageVisible = user?.moderator || user?.admin;
  const isEditBotMessageDisabled =
    isMessagesEmpty ||
    messages.at(-1)?.actor !== 'assistant' ||
    !textual ||
    typing ||
    !!editing ||
    passwording ||
    chats ||
    !isActive ||
    policy ||
    paused ||
    isLimitReached;
  const handleEditBotMessage = () => {
    const lastId = messages.at(-1)?.id;
    if (!lastId) return;
    const lastMessages = messages.filter(({ id }) => id === lastId);
    const fullMessage = lastMessages
      .map(({ message }) => message)
      .filter(line => line?.length)
      .join('\n\n');
    fillInput(fullMessage);
    setEditing(lastId);
    setInputFocus();
  };

  const isGetChatsDisabled =
    !isTextualSetted ||
    !!editing ||
    typing ||
    generating ||
    creatingSession ||
    passwording ||
    chats ||
    policy;
  const handleGetChats = () => {
    clearInput();
    setTextual(true);
    setChats(true);
  };
  const handleCloseChats = () => setChats(false);

  const isChangeBotVisible = bots?.length > 1;
  const isChangeBotDisabled =
    !bot || typing || !!editing || passwording || policy;
  const handleChangeBot = () => {
    setChats(false);
    actions.setBot('');
  };

  const [currentTime, setCurrentTime] = useState<string>();
  const onMessagesScroll = (index: number | undefined) =>
    setCurrentTime(
      index !== undefined
        ? frontDate(messages[index]?.datetime, 'dddd, DD MMM, YYYY')
        : undefined
    );

  const handleOpenGuide = () =>
    window.open('/docs/prescreenai-user-guide-v1.pdf', '_blank');

  // temporary dirty hack for typing animation inside dialog
  const lastMessage = messages?.at(-1)?.message;

  let headerTitle = null;
  if (policy) headerTitle = titles.policy;
  else if (chats) headerTitle = titles.history;

  const handleClosePage = () => {
    policy && handleClosePolicy();
    chats && handleCloseChats();
  };

  return (
    <>
      <div
        style={{ '--footer': footerHeight } as CSSProperties}
        className={cn(
          styles.root,
          { [styles.visible]: visible || configs.always },
          className
        )}
      >
        <nav className={styles.navbar}>
          <button className={styles.draggable} onClick={handleToggle} />
          <div>
            {configs.ui.collapseHeaders && headerTitle ? (
              <div className={styles.title}>
                <Button caption="Close" onClick={handleClosePage} />
                {headerTitle}
              </div>
            ) : !configs.ui.logo ? (
              <div className={styles.emptyLogo} />
            ) : domain.sifu ? (
              <SifuLogo height={24} className={styles.coach} />
            ) : domain.prescreen ? (
              <PrescreenLogo height={24} />
            ) : (
              <Logo height={24} />
            )}
            {isTextualSetted && configs.allowVoice && !passwording && !chats && (
              <Switch
                className={styles.textualSwitcher}
                items={[
                  { key: false, caption: 'Voice' },
                  { key: true, caption: 'Text' },
                ]}
                value={!!textual}
                onSwitch={handleSetTextual}
              />
            )}
            <div />
            {configs.ui.headerTime &&
              isTextualSetted &&
              !passwording &&
              !chats &&
              !policy &&
              !!currentTime?.length && (
                <div className={styles.time}>
                  <img src={CalendarIcon} />
                  <span>{currentTime}</span>
                </div>
              )}
            <Themer className={styles.themer} />
          </div>
          {isCubit && (
            <Select
              className={styles.cubitLessons}
              placeholder="Select a lesson"
              options={cubitLessons}
              onChange={actions.setCubitLesson}
            />
          )}
        </nav>
        <div className={styles.dialog}>
          {!inited ? (
            <div className={styles.loading}>
              <Loading size={40} />
            </div>
          ) : !isAuthorized ? (
            <Authorization onOpenPolicy={handleOpenPolicy} />
          ) : passwording ? (
            <PasswordForm onClose={handleClosePasswordForm} />
          ) : chats ? (
            <Chats onClose={handleCloseChats} />
          ) : !isTextualSetted ? (
            <div className={styles.configs}>
              {bots?.length > 1 && (
                <Select
                  placeholder="Select a role"
                  options={bots.map(({ id, name }) => ({
                    value: id,
                    caption: name,
                  }))}
                  disabled={!bots.length}
                  defValue={botId}
                  onChange={actions.setBot}
                />
              )}
              {configs.allowVoice && voices?.length > 1 && (
                <Select
                  placeholder="Select a voice"
                  options={voices?.map(({ id, name }) => ({
                    value: id,
                    caption: name,
                  }))}
                  disabled={!voices.length}
                  defValue={voiceId}
                  onChange={actions.setVoice}
                />
              )}
              <div>
                {configs.allowVoice && (
                  <Button
                    caption="Use voice chat"
                    disabled={isStartDisabled}
                    onClick={() => handleSetTextual(false)}
                  />
                )}
                <Button
                  caption="Use text chat"
                  disabled={isStartDisabled}
                  onClick={() => handleSetTextual(true)}
                />
              </div>
            </div>
          ) : (
            <>
              {paused && !isLimitReached && (
                <div className={styles.pause}>
                  <Button caption="Resume" onClick={handleResume} />
                </div>
              )}
              <Dialog
                loading={!loaded}
                messages={messages}
                editing={textual && editing}
                directive={directive}
                typing={typing}
                inactive={!isActive}
                limitReached={isLimitReached}
                generating={!typing && generating}
                error={error}
                onScroll={onMessagesScroll}
                onImageClick={setViewingImage}
                onDirectiveClose={handleCloseDirective}
              />
            </>
          )}
          {policy && (
            <Policy
              className={styles.over}
              accepted={user?.agreement_accepted}
              onClose={handleClosePolicy}
            />
          )}
          <div
            className={cn(styles.connection, {
              [styles.offline]: !isOnline,
              [styles.online]: isOnline && showOnline,
            })}
          >
            {isOnline ? 'Connection restored' : 'Connection lost'}
          </div>
        </div>
        <div ref={footerRef} className={styles.footer}>
          {isTextualSetted && !textual ? (
            <>
              <Button
                icon={silent ? VolumeMuteIcon : VolumeHighIcon}
                className={cn(styles.volume, { [styles.silent]: silent })}
                onClick={toggleSilent}
              />
              <Button
                icon={
                  recognizing
                    ? LoaderIcon
                    : !permitted || muted
                    ? MicrophoneDenyIcon
                    : MicrophoneIcon
                }
                className={cn(styles.microphone, {
                  [styles.recognizing]: recognizing,
                  [styles.recording]: !recognizing && permitted && recording,
                })}
                disabled={
                  !permitted || !isOnline || !isActive || !isSocketReady
                }
                onClick={handleSetMutedClick}
              />
              <div className={styles.status}>
                {!permitted
                  ? 'Need permission to record'
                  : muted
                  ? 'Voice recording is off'
                  : 'Voice recording is on'}
              </div>
            </>
          ) : (
            <div className={styles.input}>
              <Input
                placeholder="Write a message…"
                ref={inputRef}
                disabled={isSendFieldDisabled}
                multiline
                style={{ '--height': inputHeight + 'px' } as CSSProperties}
                onEsc={clearInput}
                onReturn={handleSendMessage}
                onChange={handleEditMessage}
              />
              <Button
                icon={SendIcon}
                disabled={
                  !isMessageFilled || !isOnline || !isActive || !isSocketReady
                }
                onClick={handleSendMessage}
              />
            </div>
          )}
          <div className={styles.menu}>
            <Button
              icon={MenuIcon}
              data-open
              href="javascript:void(0);"
              tabIndex={0}
              disabled={isMenuDisabled}
            />
            <div data-items tabIndex={0}>
              {!isUser && (
                <>
                  <Button
                    caption="Show sessions history"
                    disabled={isGetChatsDisabled}
                    onClick={handleGetChats}
                  />
                  <Button
                    caption="Get profile"
                    onClick={handleDownloadProfile}
                    disabled={isGetProfileDisabled}
                  />
                  <Button
                    caption="Get history"
                    onClick={handleDownloadHistory}
                    disabled={isGetHistoryDisabled}
                  />
                  <Button
                    caption="New session"
                    disabled={isNewSessionDisabled}
                    onClick={handleNewSessionClick}
                  />
                </>
              )}
              {isChangeBotVisible && (
                <Button
                  caption="Change role"
                  disabled={isChangeBotDisabled}
                  onClick={handleChangeBot}
                />
              )}
              {isEditBotMessageVisible && (
                <Button
                  caption="Edit bot message"
                  disabled={isEditBotMessageDisabled}
                  onClick={handleEditBotMessage}
                />
              )}
              {!isAnon && (
                <Button
                  caption="Change password"
                  disabled={isChangePasswordDisabled}
                  onClick={handleChangePassword}
                />
              )}
              {needPolicy && (
                <Button
                  caption="Privacy Policy"
                  disabled={policy}
                  onClick={handleOpenPolicy}
                />
              )}
              {domain.prescreen && (
                <Button caption="User guide" onClick={handleOpenGuide} />
              )}
              <Button caption="Logout" onClick={handleLogOut} />
            </div>
            <Button icon={CloseIcon} data-close />
          </div>
        </div>
      </div>
      {viewingImage && (
        <div className={styles.viewer}>
          <ImageViewer
            src={[viewingImage]}
            currentIndex={0}
            onClose={() => setViewingImage(null)}
            disableScroll
            closeOnClickOutside
          />
        </div>
      )}
    </>
  );
});

export default observer(Chat);
