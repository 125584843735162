import moment from 'moment';

const DATE_FORMAT_BACKEND = 'YYYY-MM-DD HH:mm:ss';
const DATE_FORMAT_FRONTEND = 'DD MMM YY HH:mm';

export const backDate = (value?: Date | string, format?: string): string =>
  moment.utc(value).format(format || DATE_FORMAT_BACKEND);

export const frontDate = (value?: Date | string, format?: string): string =>
  moment
    .utc(value)
    .local()
    .format(format || DATE_FORMAT_FRONTEND);
