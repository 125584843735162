import { action, runInAction } from 'mobx';
import { v4 } from 'uuid';

import { IMessage, TChatStatus } from 'types';
import store, { defState } from 'store';
import { setToken, clearToken, clearBotId, setBotId } from 'api';
import {
  createNewChat,
  getChats,
  getCubitLessons,
  getBots,
  getUserData,
  signIn,
  signUp,
  updatePassword,
  anonymous,
  sendMessageEvaluation,
} from 'api/http';
import {
  setToken as socketSetToken,
  clearToken as socketClearToken,
  setBotId as socketSetBotId,
  clearBotId as socketClearBotId,
} from 'api/socket';

export const clearStore = action(() => Object.assign(store, defState));

const INVITATION = 'invitation';
export const getInvitation = () =>
  new URLSearchParams(location.search).get(INVITATION);
export const clearInvitation = () => {
  const hewHref = location.href
    .replace(new RegExp(`${INVITATION}=[^?&]+&?`), '')
    .replace(/(\?|&)+$/, '');
  history.replaceState('', '', hewHref);
};

export const loadBots = async () => {
  const hash = getInvitation();
  const data = await getBots(hash as string);
  runInAction(() => (store.bots = data));
  hash && clearInvitation();
  return data;
};

export const loadCubitLessons = async () => {
  const data = await getCubitLessons();
  runInAction(() => (store.cubitLessons = data));
};

export const setBot = (botId: string) => {
  !botId ? clearBotId() : setBotId(botId);
  !botId ? socketClearBotId() : socketSetBotId(botId);
  runInAction(() => {
    store.botId = botId;
    store.status = 'inactive';
  });
};

export const getBot = (botId: string) =>
  store.bots.find(({ id }) => botId === id);

export const setVoice = (value: string) =>
  runInAction(() => (store.voiceId = value));

export const setCubitLesson = async (value: string) => {
  runInAction(() => (store.cubitLesson = value));
};

export const clearMessages = async () => {
  runInAction(() => (store.messages = []));
};

export const setMessages = async (data: IMessage[]) => {
  runInAction(() => (store.messages = data));
};

export const addMessage = async (message: IMessage) => {
  runInAction(() => store.messages.push(message));
};

export const editLastMessage = async (text: IMessage['message']) => {
  runInAction(
    () =>
      store.messages.length &&
      (store.messages[store.messages.length - 1].message = text)
  );
};

export const evaluateMessage = async (messageId: string, value: number) => {
  try {
    const result = await sendMessageEvaluation(messageId, value);
    const index = store.messages.findIndex(({ id }) => id === messageId);
    runInAction(
      () => index > -1 && (store.messages[index].evaluation = result)
    );
    return result;
  } catch (e) {
    return false;
  }
};

const TOKEN = 'AUTH_TOKEN';
const UUID = 'ANON_UUID';

export const saveToken = (token: string) => {
  localStorage.setItem(TOKEN, token);
  setToken(token);
  socketSetToken(token);
};

export const restoreToken = (): boolean => {
  const token = localStorage.getItem(TOKEN);
  if (token) {
    setToken(token);
    socketSetToken(token);
  }
  return !!token;
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN);
  clearToken();
  socketClearToken();
};

export const login = async (
  email: string,
  password: string
): Promise<boolean | 'inactive'> => {
  try {
    const result = await signIn(email, password);
    if (result === 'inactive') return 'inactive';
    const { user, token } = result;
    saveToken(token);
    runInAction(() => (store.user = user));
    return true;
  } catch (e) {
    return false;
  }
};

export const register = async (
  email: string,
  password: string
): Promise<boolean> => {
  try {
    const hash = getInvitation();
    const { user, token } = await signUp(email, password, hash || undefined);
    clearInvitation();
    saveToken(token);
    runInAction(() => (store.user = user));
    return true;
  } catch (e) {
    return false;
  }
};

export const changePassword = async (
  currentPassword: string,
  newPassword: string
): Promise<boolean> => {
  try {
    const token = await updatePassword(currentPassword, newPassword);
    saveToken(token);
    return true;
  } catch (e) {
    return false;
  }
};

export const anonAuth = async (): Promise<boolean | 'inactive'> => {
  try {
    let uuid = localStorage.getItem(UUID);
    if (!uuid) {
      uuid = v4();
      localStorage.setItem(UUID, uuid);
    }
    const result = await anonymous(uuid);
    if (result === 'inactive') return 'inactive';
    const { user, token } = result;
    saveToken(token);
    runInAction(() => (store.user = user));
    return true;
  } catch (e) {
    return false;
  }
};

export const logOut = () => {
  removeToken();
  setBot('');
  runInAction(() => Object.assign(store, defState));
};

export const loadUserData = async (): Promise<boolean> => {
  try {
    const user = await getUserData();
    runInAction(() => (store.user = user));
    return true;
  } catch (e) {
    return false;
  }
};

export const createNewSession = async (voice_enabled: boolean) => {
  const { botId } = store;
  const bot = getBot(botId);
  if (!bot) return;
  const result = await createNewChat(store.voiceId, voice_enabled);
  result &&
    runInAction(() => {
      store.messages = [];
      store.status = 'active';
    });
  return result;
};

export const loadChats = async () => {
  try {
    const { botId } = store;
    const bot = getBot(botId);
    if (!bot) return;
    const data = await getChats();
    runInAction(() => (store.chats = data));
    return true;
  } catch (e) {
    return false;
  }
};

export const clearChats = async () =>
  runInAction(() => (store.chats = defState.chats));

export const setChatStatus = async (value: TChatStatus) =>
  runInAction(() => (store.status = value));
