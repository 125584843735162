import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface IProps {
  checked?: boolean;
  onChange?: (() => void) | ((value: boolean) => void);
  className?: string;
}

const Checkbox: React.FC<PropsWithChildren<IProps>> = ({
  checked,
  onChange,
  className,
  children,
}) => (
  <label data-component="checkbox" className={cn(styles.checkbox, className)}>
    <input
      type="checkbox"
      checked={checked}
      onClick={() => onChange?.(!checked)}
      onChange={() => null}
    />
    <div className={styles.box}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        viewBox="12 12 76 76"
      >
        <path
          fill="#000000"
          d="M41.373,86c-2.06,0-4.041-0.822-5.518-2.287l-19.572-19.59c-3.044-3.043-3.044-7.977,0-11.027  c3.05-3.05,7.983-3.05,11.033,0l12.88,12.875l33.39-49.645c2.406-3.573,7.254-4.521,10.832-2.113  c3.576,2.396,4.52,7.248,2.129,10.826L47.848,82.547c-1.308,1.939-3.397,3.186-5.72,3.41C41.878,85.979,41.628,86,41.373,86"
        />
      </svg>
    </div>
    <div className={styles.content}>{children}</div>
  </label>
);

export default Checkbox;
