import React from 'react';
import Markdown from 'react-markdown';
import cn from 'classnames';

import configs from 'configs';
import { frontDate } from 'utils/format';

import Button from 'components/button';

import policies from 'policies';

import styles from '../styles';

export const getPolicy = (): string | null => {
  if (!location.host?.length) return null;
  const key = Object.keys(policies).find(key => location.host.includes(key));
  return policies[key as keyof typeof policies] || null;
};

interface IProps {
  className?: string;
  accepted?: string;
  onClose?: () => void;
}

const Policy: React.FC<IProps> = ({ className, accepted, onClose }) => {
  const policy = getPolicy();

  return (
    <div className={cn(styles.policy, className)}>
      {!configs.ui.collapseHeaders && (
        <div className={styles.header}>
          <span>Privacy Policy</span>
          <Button onClick={onClose}>Close</Button>
        </div>
      )}
      <div>
        <Markdown
          components={{
            a: ({ href, children }) => (
              <a href={href} target="_blank" rel="noreferrer">
                {children}
              </a>
            ),
          }}
        >
          {policy}
        </Markdown>
        {accepted && (
          <div className={styles.accepted}>Accepted: {frontDate(accepted)}</div>
        )}
      </div>
    </div>
  );
};

export const policyTitle = 'Privacy Policy';

export default Policy;
