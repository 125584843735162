/* eslint-disable no-undef */

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])){3}$/
    )
);

export function register(config) {
  console.log('register 1');

  if (!navigator.serviceWorker) return;

  console.log('register 2');

  window.addEventListener('beforeinstallprompt', () => {
    console.log('register beforeinstallprompt');
  });

  window.addEventListener('load', () => {
    const swUrl = `/service-worker.js`;

    console.log('register 3');

    if (isLocalhost) {
      checkValidServiceWorker(swUrl, config);
      navigator.serviceWorker.ready.then(() => {
        console.log(
          'This application is served through a Service Worker cache.'
        );
      });
    } else {
      registerValidSW(swUrl, config);
    }
  });
}

function registerValidSW(swUrl, config) {
  console.log('register 4');

  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      console.log('register 5', registration);

      registration.onupdatefound = () => {
        console.log('register 6');

        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        console.log('register 7');

        installingWorker.onstatechange = () => {
          console.log('register 8');

          if (installingWorker.state === 'installed') {
            console.log('register 9');

            if (navigator.serviceWorker.controller) {
              console.log('New version available.');
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              console.log('Content is cached for offline use.');
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch(error => {
      console.error('Error registering Service Worker:', error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl)
    .then(response => {
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection. The application works offline.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
