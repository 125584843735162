import React, {
  FormEventHandler,
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

import configs, { domain } from 'configs';
import useConnection from 'utils/connection';
import {
  login,
  register,
  loadBots,
  getInvitation,
  anonAuth,
} from 'store/actions';

import Button from 'components/button';
import Input from 'components/input';
import Checkbox from 'components/checkbox';

import TalktostoryLogo from 'components/icons/talktostory';

import { getPolicy } from './policy';

import styles from '../styles';

interface IProps {
  onOpenPolicy?: () => void;
  className?: string;
}

enum ELoading {
  LOGIN = 'login',
  REGISTRATION = 'registration',
  ANON = 'anon',
}

const Authorization: React.FC<IProps> = ({ onOpenPolicy, className }) => {
  const [policyApplied, setPolicyApplied] = useState<boolean>(false);
  const [loading, setLoading] = useState<ELoading | false>(false);
  const [error, setError] = useState<string | false>(false);

  const isOnline = useConnection();

  const invitation = getInvitation();
  const needSigningUp = !!invitation || configs.registration;
  const invitationBotName = useMemo(() => {
    try {
      const data = invitation?.split('.')?.[1];
      if (!data) return null;
      const json = atob(data);
      const { bot_name } = JSON.parse(json);
      return bot_name as string;
    } catch (e) {
      return null;
    }
  }, [invitation]);
  const [signingUp, setSigningUp] = useState<boolean>(needSigningUp);

  useEffect(() => setError(false), [signingUp]);

  const handlePolicyClick: MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault();
    onOpenPolicy?.();
  };

  const formRef = useRef<HTMLFormElement>(null);
  const getFormData = () =>
    Object.fromEntries(
      Array.from(
        new FormData(formRef.current as HTMLFormElement).entries()
      ).map(([name, value]) => [name, (value as string).trim()])
    );

  const checkData = (data: { [p: string]: string }) =>
    !Object.values(data).some(value => !value.length);

  const [isFilled, setFilled] = useState<boolean>(false);
  const [isEmpty, setEmpty] = useState<boolean>(true);
  const handleFormChange = () =>
    void setTimeout(() => {
      const data = getFormData();
      setFilled(checkData(data));
      setEmpty(Object.values(data).every(value => !value.length));
    }, 0);
  useEffect(handleFormChange, [signingUp]);

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    const data = getFormData();
    if (!checkData(data)) return;
    if (signingUp && data.password !== data.confirm) {
      setError('Confirmation is not the same as password');
      return;
    }
    try {
      setError(false);
      setLoading(signingUp ? ELoading.REGISTRATION : ELoading.LOGIN);
      const result = await (signingUp ? register : login)(
        data.email,
        data.password
      );
      if (result === 'inactive') {
        setError('User is inactive');
      } else if (!result) {
        setError(signingUp ? 'Something went wrong' : 'Invalid credentials');
      } else {
        const bots = await loadBots();
        if (!bots.length) setError('You are not subscribed to any bot');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAnonClick = async () => {
    try {
      setError(false);
      setLoading(ELoading.ANON);
      const result = await anonAuth();
      if (result === 'inactive') {
        setError('User is inactive');
      } else if (!result) {
        setError(signingUp ? 'Something went wrong' : 'Invalid credentials');
      } else {
        const bots = await loadBots();
        if (!bots.length) setError('There are no bots available');
      }
    } finally {
      setLoading(false);
    }
  };

  const needPolicy = signingUp && !!getPolicy();

  return (
    <form
      ref={formRef}
      className={cn(styles.auth, { [styles.incorrect]: error }, className)}
      onSubmit={handleFormSubmit}
      onChange={handleFormChange}
      onReset={handleFormChange}
    >
      {domain.story && <TalktostoryLogo className={styles.logo} />}
      {configs.anonymous && (
        <div className={styles.anon}>
          <Button
            caption="Try chat"
            loading={loading === ELoading.ANON}
            disabled={!!loading}
            onClick={handleAnonClick}
          />
          <div className={styles.or}>or</div>
        </div>
      )}
      <div className={styles.description}>
        {signingUp && (
          <p>
            {invitationBotName
              ? `Welcome to ${invitationBotName} sign up!`
              : 'Welcome to the registration form!'}
            <br />
            Enter your email and desired password to continue.
          </p>
        )}
      </div>
      <p className={styles.error}>{error}</p>
      <Input placeholder="Email" name="email" required disabled={!!loading} />
      <Input
        placeholder="Password"
        name="password"
        required
        secure
        disabled={!!loading}
      />
      {needSigningUp && signingUp && (
        <Input
          placeholder="Confirm password"
          name="confirm"
          required
          secure
          disabled={!!loading}
        />
      )}
      {needPolicy && (
        <Checkbox checked={policyApplied} onChange={setPolicyApplied}>
          I have read and accept the{' '}
          <a onClick={handlePolicyClick} className={styles.policyLink}>
            Privacy Policy
          </a>
        </Checkbox>
      )}
      <div className={styles.buttons}>
        <Button
          caption={signingUp ? 'Sign Up' : 'Sign In'}
          type="submit"
          loading={[ELoading.REGISTRATION, ELoading.LOGIN].includes(
            loading as ELoading
          )}
          disabled={
            !!loading ||
            !isOnline ||
            !isFilled ||
            (signingUp && needPolicy && !policyApplied)
          }
        />
        <Button
          invert
          className={styles.switcher}
          type={needSigningUp ? 'button' : 'reset'}
          disabled={!!loading || (!needSigningUp && isEmpty)}
          onClick={
            !needSigningUp ? undefined : () => setSigningUp(value => !value)
          }
        >
          <div>
            {!needSigningUp
              ? ''
              : signingUp
              ? `Already have an account?`
              : `Don’t have an account?`}
          </div>
          <div>
            {!needSigningUp ? 'Discard' : signingUp ? `Sign In` : `Sign Up`}
          </div>
        </Button>
      </div>
    </form>
  );
};

export default Authorization;
