import cn from 'classnames';

import { domain } from 'configs';

import commonStyles from './styles.module.scss';
import storyStyles from './story.module.scss';

const styles = { ...commonStyles };

if (domain.story)
  Object.entries(storyStyles).forEach(
    ([key, value]) => (styles[key] = cn(styles[key], value))
  );

export default styles;
