import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface IProps {
  switched?: boolean;
  leftIcon?: string;
  rightIcon?: string;
  onToggle?: (() => void) | ((value: boolean) => void);
  className?: string;
}

const Toggle: React.FC<IProps> = ({
  switched,
  leftIcon,
  rightIcon,
  onToggle,
  className,
}) => (
  <label className={cn(styles.root, className)}>
    <input
      type="checkbox"
      checked={switched}
      onClick={() => onToggle?.(!switched)}
      onChange={() => null}
    />
    <div className={styles.switcher}>
      <img className={styles.icon} src={leftIcon} />
      <img className={styles.icon} src={rightIcon} />
    </div>
    <div className={styles.back} />
  </label>
);

export default Toggle;
