/* eslint-disable no-undef */

const checkDomain = (...elements: string[]) =>
  elements.some(chunk => location.host.includes(chunk));

export const domain = {
  sifu: checkDomain('life-coach', 'sifu'),
  prescreen: checkDomain('prescreenai'),
  story: checkDomain('talktostory', 'localhost'),
  dev: checkDomain('ai-chat-ui.dev.processica.com'),
  localhost: checkDomain('localhost'),
};

const configs = {
  api: process.env.REACT_APP_API_HOST,
  loging: process.env.REACT_APP_LOGING === 'true',
  allowVoice: process.env.REACT_APP_ALLOW_VOICE === 'true' && !domain.story,
  anonymous: domain.localhost || domain.story,
  always: domain.story,
  registration: domain.story,
  ui: {
    logo: !domain.story,
    collapseMessages: domain.story,
    typing: !domain.story,
    collapseHeaders: domain.story,
    headerTime: domain.story,
  },
};

export default configs;
