import configs from 'configs';
import {
  IChat,
  IMessage,
  IBot,
  IUser,
  TChatStatus,
  TChatMessageEvaluation,
} from 'types';
import { IOption } from 'components/select';

import { get, post } from './index';

export const getBots = async (invitation?: string): Promise<IBot[]> => {
  try {
    const data = await get<IBot[]>('bots', {
      invitation,
    });
    configs.loging && console.log('GET bots', data);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to get bots');
  }
};

export const getCubitLessons = async (): Promise<IOption[]> => {
  try {
    const data = await get<Array<string>>('cubit_lessons');
    configs.loging && console.log('GET cubit_lessons', data);
    return data.map(caption => ({
      caption,
      value: caption,
    }));
  } catch (e) {
    console.error(e);
    throw new Error('Failed to get cubit lessons');
  }
};

export const signUp = async (
  email: string,
  password: string,
  invitation?: string
): Promise<{ user: IUser; token: string }> => {
  try {
    const params = { email, password, hash: invitation || '' };
    const data = await post<IUser & { token: string }>(
      ['user', 'signup'],
      params
    );
    configs.loging && console.log('POST signup', params, data);
    const { token, ...user } = data;
    return { user, token };
  } catch ({ message, cause }) {
    console.error(message, cause);
    throw new Error('Failed to sign up');
  }
};

export const signIn = async (
  email: string,
  password: string
): Promise<{ user: IUser; token: string } | 'inactive'> => {
  try {
    const params = { email, password };
    const data = await post<IUser & { token: string }>(
      ['user', 'login'],
      params
    );
    configs.loging && console.log('POST login', params, data);
    const { token, ...user } = data;
    return { user, token };
  } catch ({ message, cause }) {
    if (cause === 'inactive') {
      return 'inactive';
    }
    console.error(message, cause);
    throw new Error('Failed to sign in');
  }
};

export const anonymous = async (
  anon_uuid: string
): Promise<{ user: IUser; token: string } | 'inactive'> => {
  try {
    const params = { anon_uuid };
    const data = await post<IUser & { token: string }>(
      ['user', 'anonymous'],
      params
    );
    configs.loging && console.log('POST anonymous', params, data);
    const { token, ...user } = data;
    return { user, token };
  } catch ({ message, cause }) {
    if (cause === 'inactive') {
      return 'inactive';
    }
    console.error(message, cause);
    throw new Error('Failed to sign in anonymously');
  }
};

export const getUserData = async (): Promise<IUser> => {
  try {
    const data = await get<IUser>('user');
    configs.loging && console.log('GET user', data);
    return data;
  } catch ({ message, cause }) {
    console.error(message, cause);
    throw new Error('Failed to get user');
  }
};

export const updatePassword = async (
  password: string,
  renewed: string
): Promise<string> => {
  try {
    const params = { password, renewed };
    const data = await post<{ token: string }>(['user', 'password'], params);
    configs.loging && console.log('POST new_password', params, data);
    return data.token;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to update password');
  }
};

export const createNewChat = async (
  voice_id: string,
  voice_enabled: boolean
): Promise<boolean> => {
  try {
    const params = { voice_id, voice_enabled };
    const data = await post('create_new_chat', params);
    configs.loging && console.log('POST create_new_chat', params, data);
    return true;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to create new chat');
  }
};

export const getHistory = async (): Promise<{
  messages: IMessage[];
  status: TChatStatus;
}> => {
  try {
    const data = await get<
      { messages: IMessage[]; status: TChatStatus } | IMessage[]
    >(`chat_history`);
    configs.loging && console.log('GET chat_history', data);
    return data instanceof Array ? { messages: data, status: 'active' } : data;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to get history');
  }
};

export const getChats = async (): Promise<IChat[]> => {
  try {
    const data = await get<IChat[]>(`chats`);
    configs.loging && console.log('GET chats', data);
    return data.filter(({ messages }) => messages?.length);
  } catch (e) {
    console.error(e);
    throw new Error('Failed to get chats');
  }
};

export const sendMessageEvaluation = async (
  message_id: string,
  value: number
): Promise<TChatMessageEvaluation> => {
  try {
    const params = { message_id, value };
    const data = await post<TChatMessageEvaluation>(`evaluate_message`, params);
    configs.loging && console.log('POST evaluate_message', params, data);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to evaluate message');
  }
};
